.camper-image {
  height: 50px;
  vertical-align: middle;
  margin-right: 15px;
  border: 2px solid #2c3e50;
  border-radius: 50px;
}

td, a {
  font-size: 14px;
  font-weight: 600;
  color: #34495e;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

a:visited {
  color: black;
}

tr:nth-child(even) td {
  background-color: rgba(250, 250, 250);
}

tr:nth-child(odd) td {
  background-color: rgb(245,245,245);
}