.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('https://i.imgur.com/yFdYs7G.jpg');
  background-size: cover;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 130px;
}

.main-header {
  background-color: #220b77;
  color: white;
}

.App-header {
  padding-top: 20px;  
}

.App-title {
  font-size: 1.5em;
}

.main-header {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  font-size: 20px;
  border-radius: 25px 25px 0 0;
}

.App-footer {
  margin-top: auto;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
}

.App-footer a:link {
  font-size: 20px;
  font-weight: bold;
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.App-footer a:visited {
  font-size: 20px;
  font-weight: bold;
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.overflow-table {
  overflow-x:auto;
}

.App-tracker-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* @keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */
