.Playoffs-div {
    background-color: rgb(245,245,245);
    opacity: 0.7;
    border: 2px solid rgb(200,200,200);
    margin-left: 10%;
    margin-right: 10%;
}

.Playoffs-div h2 {
    color: black;
}

.Playoffs-prediction-true {
    color: green;
    font-weight: bold;
    -webkit-text-fill-color: green; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
}

.Playoffs-prediction-false {
    color: red;
    font-weight: bold;
    -webkit-text-fill-color: red; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
}