.backButton {
    margin-top: 20px;
    color: white;
    background-color: rgb(245,245,245);
    border: 2px solid rgb(200,200,200);
}

.playoffButton { 
    color: black;
    margin-top: 20px;
    background-color: rgb(245,245,245);
    border: 2px solid rgb(200,200,200);
}

.playoffButton:link { 
    color: black;
    margin-top: 20px;
    background-color: rgb(245,245,245);
    border: 2px solid rgb(200,200,200);
}

.playoffButton:visited { 
    color: black;
    margin-top: 20px;
    background-color: rgb(245,245,245);
    border: 2px solid rgb(200,200,200);
}